import { Action, State } from '@/models/ReducerModels';

export const onboardingReducer = (state: State, action: Action) => {
  if (!state.page) state.page = 0;
  switch (action.type) {
    case 'PAGE NEXT':
      if (state.page < 6) {
        state.page = state.page + 1;
      }
      return {
        ...state,
      };

    case 'PAGE BACK':
      if (state.page > 0) {
        state.page = state.page - 1;
      }
      return {
        ...state,
      };

    case 'SET PAGE':
      state.page = action.payload?.page;
      return {
        ...state,
      };

    default:
      return state;
  }
};
