import React from 'react';
import ReactDOM from 'react-dom/client';
import { hotjar } from 'react-hotjar';
import { BrowserRouter } from 'react-router-dom';

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { Auth0ProviderWithNavigate } from './components/utility/auth/Auth0ProviderWithHistory';
import { reactPlugin } from './services/ApplicationInsightsService';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';

hotjar.initialize(3591769, 6);

export const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const currentDomain = window.location.href;
if (currentDomain.includes('127.0.0.1:3000')) {
  const newDomain = currentDomain.replace('127.0.0.1:3000/', 'localhost:3000/');
  window.location.assign(newDomain);
}

if (import.meta.env.VITE_NODE_ENV === 'prod') {
  // eslint-disable-next-line no-console
  console.log = () => {
    return;
  };
}

root.render(
  <AppInsightsContext.Provider value={reactPlugin}>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </AppInsightsContext.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
