import React, { useEffect, useReducer } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import ProtectedRoute from './components/utility/auth/ProtectedRoute';
import ScreenSizePlaceholder from './components/utility/ScreenSizePlaceholder';
import SnackbarComponent from './components/utility/Snackbar';
import AppContext from './contexts/AppContext';
import useScreenBreakPoint from './hooks/useScreenBreakPoint';
import AddPlaylistPage from './pages/AddPlaylistPage';
import AllPlaylistPitchesPage from './pages/AllPlaylistPitchesPage';
import HomePage from './pages/Home';
import LoginPage from './pages/LoginPage';
import OnboardingPage from './pages/Onboarding';
import PaypalCallbackPage from './pages/PaypalCallbackPage';
import PlaylistPage from './pages/Playlist';
import PlaylistsPage from './pages/Playlists';
import SettingsPage from './pages/SettingsPage';
import TotalBalancePage from './pages/TotalBalancePage';
import snackbarReducer from './reducers/snackbarReducer';
import { initApi } from './utility/api';

import './App.scss';

function App() {
  const { breakPointHit } = useScreenBreakPoint();
  initApi(import.meta.env.VITE_API_URL);
  const location = useLocation();

  useEffect(() => {
    const currentDomain = window.location.href;
    if (currentDomain.includes('paypal-callback') && currentDomain.includes('state=')) {
      const newDomain = currentDomain.replace('code=', 'ppcode=').replace('state=', 'other=');
      window.location.href = newDomain;
    }
  }, []);

  const initialState = {
    message: '',
    type: '',
    snackbar: {
      message: '',
      type: '',
      open: false,
      severity: '',
    },
  };
  const [snackbar, dispatchSnackbar] = useReducer(snackbarReducer, initialState);

  if (breakPointHit && !location.pathname.includes('paypal-callback')) return <ScreenSizePlaceholder />;

  return (
    <AppContext.Provider value={{ snackbar, dispatchSnackbar }}>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/dashboard" element={<HomePage />} />
          <Route path="/playlists" element={<PlaylistsPage />} />
          <Route path="/playlists/:slug" element={<PlaylistPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/dashboard/totalbalance" element={<TotalBalancePage />} />
          <Route path="/dashboard/pendingpitches" element={<AllPlaylistPitchesPage />} />
          <Route path="/add-playlist" element={<AddPlaylistPage />} />
          <Route path="/onboarding" element={<OnboardingPage />} />
          <Route path="/paypal-callback" element={<PaypalCallbackPage />} />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Route>
      </Routes>
      <SnackbarComponent />
    </AppContext.Provider>
  );
}

export default App;
